import React, { useEffect, useRef, useState } from "react"
import items from "./pay_roll.json"
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './scroll.scss'


const InfoScrollbar = (props) => {
  const [item, setItem] = useState({
    "image": "https://dl.letscubo.com/avatarIImage/Frame+47.png",
    "email": "st******@gmail"
  });
  const index = useRef(0);
  useEffect(() => {
    let timer = null;
    const fn = (duration = 800) => {
      // console.log(duration);
      timer = setTimeout(() => {
        setItem(items[index.current]);
        if(index.current === items.length - 1) {
          index.current = 0;
        } else {
          index.current++;
        }
        fn(Math.floor(Math.random() * 21 + 10) * 100);
      }, duration)
    }
    fn();
    return () => {
      clearInterval(timer);
    }
  }, []);
  return (
    <div className={`marquee-wrap marquee-wrap-${props.extraClass}`}>
      <div className="marquee">
        <div className="marquee-item mask">
          <div className="avatar">
            <img
              loading="lazy"
              src={` https://cubo.to/img?width=48&image=${item.image}`}
              alt="user_avatar"
              width={24}
              height={24}
            />
          </div>
          <span>{item.email ?? " ****@gmail"}&nbsp;has started the trial.</span>
        </div>
      </div>
    </div>
  )
}

export default InfoScrollbar
